import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategory } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { ThreeDStockIndexPageMaterialsCategoriesNavHead } from '../ThreeDStockIndexPageMaterialsCategoriesNavHead';
import { ThreeDStockIndexPageMaterialsCategoriesNavList } from '../ThreeDStockIndexPageMaterialsCategoriesNavList';

interface ThreeDStockIndexPageMaterialsCategoriesNavBodyProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  isShowAll?: boolean;
  materialCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategory[];
  withNavHead?: boolean;
}

function ThreeDStockIndexPageMaterialsCategoriesNavBody({
  allCustomI18nText,
  filtersNavBasePath,
  isShowAll,
  materialCategories,
  withNavHead
}: ThreeDStockIndexPageMaterialsCategoriesNavBodyProps) {
  return (
    <Fragment>
      {withNavHead && <ThreeDStockIndexPageMaterialsCategoriesNavHead />}

      <ThreeDStockIndexPageMaterialsCategoriesNavList
        allCustomI18nText={allCustomI18nText}
        filtersNavBasePath={filtersNavBasePath}
        isShowAll={isShowAll}
        materialCategories={materialCategories}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavBody;
