import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ThreeDStockIndexPageMenu } from '../../../../stock/components/menus/ThreeDStockIndexPageMenu';
import { CompactStockSecondaryMenu } from '../CompactStockSecondaryMenu';

import { CommonPermissions } from '../../../commonConstants';

function DefaultSecondaryMenuStock() {
  const currentUser = useCurrentUser();

  if (currentUser.hasPermissions(CommonPermissions.READ_COMPACT_STOCK_MENU)) {
    return <CompactStockSecondaryMenu />;
  }

  return <ThreeDStockIndexPageMenu />;
}

export default DefaultSecondaryMenuStock;
