import React, { memo, ReactNode, useEffect } from 'react';
import cl from 'classnames';

import { SingleUserPageHeaderUser } from '../../../../main/users/components/menus/SingleUserProfileSecondaryMenuWrapperHead/SingleUserProfileSecondaryMenuWrapperHead.types';
import { WorkspaceSecondaryMenuItems } from '../../../../main/common/components/menus/CompactWorkspaceSecondaryMenu/CompactWorkspaceSecondaryMenu.types';
import { PaymentsSecondaryMenuItems } from '../../../../main/common/components/menus/CompactPaymentsSecondaryMenu/CompactPaymentsSecondaryMenu.types';
import { StockSecondaryMenuItems } from '../../../../main/common/components/menus/CompactStockSecondaryMenu/CompactStockSecondaryMenu.types';

import { useMenu } from '../../../hooks/useMenu';

import { CompactSecondaryMenuWrapperHead } from './components/CompactSecondaryMenuWrapperHead';
import { SecondaryMenuWrapperBackdrop } from './components/SecondaryMenuWrapperBackdrop';
import { SecondaryMenuWrapperBodyWrapper } from './components/SecondaryMenuWrapperBodyWrapper';
import { SecondaryMenuWrapperCloseButton } from './components/SecondaryMenuWrapperCloseButton';
import { SecondaryMenuWrapperHead } from './components/SecondaryMenuWrapperHead';
import { SingleUserProfileSecondaryMenuWrapperHead } from '../../../../main/users/components/menus/SingleUserProfileSecondaryMenuWrapperHead';
import { TeamProfileSecondaryMenuWrapperHead } from '../../../../main/teams/components/menus/components/TeamProfileSecondaryMenuWrapperHead';
import {
  TeamShowPageHeaderFinTeam,
  TeamShowPageHeaderTeam
} from '../../../../main/teams/components/headers/TeamShowPageHeader';

import { DropdownLinksHelperLinks } from '../../../../helpers/DropdownLinksHelper';

interface SecondaryMenuWrapperProps {
  activeMenu?:
    | WorkspaceSecondaryMenuItems
    | PaymentsSecondaryMenuItems
    | StockSecondaryMenuItems;
  children: ReactNode;
  customHead?: ReactNode;
  finTeam?: TeamShowPageHeaderFinTeam;
  i18nTitle?: string;
  isCompact?: boolean;
  menuLinks?: DropdownLinksHelperLinks;
  team?: TeamShowPageHeaderTeam;
  teamLoaded?: boolean;
  user?: SingleUserPageHeaderUser;
  userLoaded?: boolean;
}

function SecondaryMenuWrapper({
  activeMenu,
  children,
  customHead,
  finTeam,
  i18nTitle,
  isCompact = false,
  menuLinks,
  team,
  teamLoaded,
  user,
  userLoaded
}: SecondaryMenuWrapperProps) {
  const {
    isSecondaryMenuOpen,
    isSecondaryMenuKeepOpen,
    closeSecondaryMenu,
    onMountSecondarySidebar,
    onUnmountSecondarySidebar,
    currentPathTab
  } = useMenu();

  useEffect(() => {
    onMountSecondarySidebar();
    return () => {
      onUnmountSecondarySidebar();
    };
  }, []);

  return (
    <div
      data-id="nav-secondary-wrap"
      className={cl(
        'absolute h-full w-full z-10 pointer-events-none',
        isSecondaryMenuKeepOpen ? 'xl:relative xl:w-auto' : null
      )}
    >
      <div
        data-id="nav-secondary-inner"
        className={cl(
          'relative z-10 flex h-full pointer-events-auto w-96 4xl:w-112 max-w-full transform transition-transform',
          isSecondaryMenuOpen ? null : '-translate-x-full',
          isSecondaryMenuKeepOpen ? 'xl:transform-none xl:w-80' : null
        )}
      >
        <nav
          data-id="nav-secondary"
          className={cl(
            'border-gray-200 border-r bg-white dark:bg-gray-900 dark:border-gray-700 flex flex-auto flex-col h-full',
            isSecondaryMenuOpen ? 'shadow-xl' : null,
            isSecondaryMenuKeepOpen ? 'xl:shadow-none xl:pl-0' : null
          )}
        >
          {!customHead && team && (
            <TeamProfileSecondaryMenuWrapperHead
              team={team}
              finTeam={finTeam}
              loaded={teamLoaded}
            />
          )}

          {!customHead && user && (
            <SingleUserProfileSecondaryMenuWrapperHead
              user={user}
              loaded={userLoaded}
            />
          )}

          {!customHead && !user && !team && (
            <>
              {isCompact ? (
                <CompactSecondaryMenuWrapperHead
                  activeMenu={activeMenu}
                  i18nMenuTitle={i18nTitle}
                  menuLinks={menuLinks}
                  tab={currentPathTab}
                />
              ) : (
                <SecondaryMenuWrapperHead i18nTitle={i18nTitle} />
              )}
            </>
          )}

          {customHead ? customHead : null}

          <SecondaryMenuWrapperBodyWrapper>
            {children}
          </SecondaryMenuWrapperBodyWrapper>
        </nav>

        <SecondaryMenuWrapperCloseButton
          isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
          closeSecondaryMenu={closeSecondaryMenu}
        />
      </div>

      <SecondaryMenuWrapperBackdrop
        isSecondaryMenuOpen={isSecondaryMenuOpen}
        closeSecondaryMenu={closeSecondaryMenu}
      />
    </div>
  );
}

export default memo<SecondaryMenuWrapperProps>(SecondaryMenuWrapper);
