import { StockSecondaryMenuItems } from './CompactStockSecondaryMenu.types';

import { useCompactStockSecondaryMenu } from './hooks/useCompactStockSecondaryMenu';

import { CompactMenuCreateLibraryLink } from '../CompactMenuCreateLibraryLink';
import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { ThreeDStockIndexPageLifestylesCategoriesNav } from '../../../../stock/components/menus/ThreeDStockIndexPageLifestylesCategoriesNav';
import { ThreeDStockIndexPageMaterialsCategoriesNav } from '../../../../stock/components/menus/ThreeDStockIndexPageMaterialsCategoriesNav';
import { ThreeDStockIndexPageProductsCategoriesNav } from '../../../../stock/components/menus/ThreeDStockIndexPageProductsCategoriesNav';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { LifestylePath } from '../../../../lifestyles/LifestylePath';
import { MaterialPath } from '../../../../materials/MaterialPath';
import { ProductPath } from '../../../../products/ProductPath';

import { StockPermissions } from '../../../../stock/stockConstants';

function CompactStockSecondaryMenu() {
  const { activeMenu, i18nMenuTitle, menuLinks } =
    useCompactStockSecondaryMenu();

  return (
    <SecondaryMenuWrapper
      activeMenu={activeMenu}
      i18nTitle={i18nMenuTitle}
      isCompact
      menuLinks={menuLinks}
    >
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_CREATE_LIBRARY_LINK}
      >
        <div className="p-4">
          <div className="flex gap-1 items-center">
            <CompactMenuCreateLibraryLink />
          </div>
        </div>
      </CheckPermissions>

      {activeMenu === StockSecondaryMenuItems.PRODUCTS && (
        <ThreeDStockIndexPageProductsCategoriesNav
          filtersNavBasePath={ProductPath.index()}
          isShowAll
        />
      )}

      {activeMenu === StockSecondaryMenuItems.LIFESTYLES && (
        <ThreeDStockIndexPageLifestylesCategoriesNav
          filtersNavBasePath={LifestylePath.index()}
          isShowAll
        />
      )}

      {activeMenu === StockSecondaryMenuItems.MATERIALS && (
        <CheckPermissions
          action={StockPermissions.READ_STOCK_MENU_MATERIALS_NAV}
        >
          <ThreeDStockIndexPageMaterialsCategoriesNav
            filtersNavBasePath={MaterialPath.index()}
            isShowAll
          />
        </CheckPermissions>
      )}
    </SecondaryMenuWrapper>
  );
}

export default CompactStockSecondaryMenu;
