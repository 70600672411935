import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { LifestylesFiltersNavBasePath } from '../../../../../../lifestyles/lifestylesTypes';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavHead } from '../ThreeDStockIndexPageLifestylesCategoriesNavHead';
import { ThreeDStockIndexPageLifestylesCategoriesNavList } from '../ThreeDStockIndexPageLifestylesCategoriesNavList';

interface ThreeDStockIndexPageLifestylesCategoriesNavBodyProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: LifestylesFiltersNavBasePath;
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategory[];
  isShowAll?: boolean;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageLifestylesCategoriesNavBody({
  allCustomI18nText,
  filtersNavBasePath,
  lifestyleCategories,
  isShowAll,
  withNavHead
}: ThreeDStockIndexPageLifestylesCategoriesNavBodyProps) {
  return (
    <Fragment>
      {withNavHead && <ThreeDStockIndexPageLifestylesCategoriesNavHead />}

      <ThreeDStockIndexPageLifestylesCategoriesNavList
        lifestyleCategories={lifestyleCategories}
        filtersNavBasePath={filtersNavBasePath}
        allCustomI18nText={allCustomI18nText}
        isShowAll={isShowAll}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavBody;
