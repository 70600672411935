import React from 'react';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { MenuCreateLibraryLink } from '../../../../common/components/menus/MenuCreateLibraryLink';
import { ThreeDStockIndexPageLibraryNav } from '../ThreeDStockIndexPageLibraryNav';
import { ThreeDStockIndexPageProductsCategoriesNav } from '../ThreeDStockIndexPageProductsCategoriesNav';
import { ThreeDStockIndexPageLifestylesCategoriesNav } from '../ThreeDStockIndexPageLifestylesCategoriesNav';
import { ThreeDStockIndexPageMaterialsCategoriesNav } from '../ThreeDStockIndexPageMaterialsCategoriesNav';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { StockPermissions } from '../../../stockConstants';

import { words } from '../../../../../locales/keys';

import { ProductPath } from '../../../../products/ProductPath';
import { LifestylePath } from '../../../../lifestyles/LifestylePath';
import { MaterialPath } from '../../../../materials/MaterialPath';

function ThreeDStockIndexPageMenu() {
  return (
    <SecondaryMenuWrapper i18nTitle={words.threeDStock}>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_CREATE_LIBRARY_LINK}
      >
        <MenuCreateLibraryLink />
      </CheckPermissions>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_LIBRARY_SECTION}
      >
        <ThreeDStockIndexPageLibraryNav />
      </CheckPermissions>
      <ThreeDStockIndexPageProductsCategoriesNav
        filtersNavBasePath={ProductPath.index()}
        withNavHead
      />
      <ThreeDStockIndexPageLifestylesCategoriesNav
        filtersNavBasePath={LifestylePath.index()}
        withNavHead
      />
      <CheckPermissions action={StockPermissions.READ_STOCK_MENU_MATERIALS_NAV}>
        <ThreeDStockIndexPageMaterialsCategoriesNav
          filtersNavBasePath={MaterialPath.index()}
          withNavHead
        />
      </CheckPermissions>
    </SecondaryMenuWrapper>
  );
}

export default ThreeDStockIndexPageMenu;
