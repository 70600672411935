import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import { I18nText } from '../../../../../types';

import {
  MaterialClientID,
  MaterialsFiltersNavBasePath
} from '../../../../materials/materialsTypes';

import { FetchMaterialCategoriesQueryResponse } from '../../../../materials/queries/fetchMaterialCategories.query';

import { useMaterialCategories } from '../../../../materials/hooks/useMaterialCategories';

import { ThreeDStockIndexPageMaterialsCategoriesNavBody } from './components/ThreeDStockIndexPageMaterialsCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getMaterialClientIdsCacheKeyPart } from '../../../../materials/utils/getMaterialClientIdsCacheKeyPart';

import { MaterialCache } from '../../../../materials/MaterialCache';

interface ThreeDStockIndexPageMaterialsCategoriesNavProps {
  allCustomI18nText?: I18nText;
  clientIds?: MaterialClientID[];
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  isShowAll?: boolean;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageMaterialsCategoriesNav({
  allCustomI18nText,
  clientIds,
  filtersNavBasePath,
  isShowAll,
  withNavHead
}: ThreeDStockIndexPageMaterialsCategoriesNavProps) {
  const cacheKey = isEmpty(clientIds)
    ? MaterialCache.categoriesCacheKey()
    : MaterialCache.categoriesLibraryCacheKey(
        getMaterialClientIdsCacheKeyPart({ clientIds })
      );
  const addInitialFilters = isEmpty(clientIds)
    ? null
    : { materialClientIds: clientIds };

  const {
    materialCategories,
    materialCategoriesErrorMessage,
    materialCategoriesFetched,
    materialCategoriesIsPlaceholderData
  } = useMaterialCategories({ cacheKey, addInitialFilters });

  const materialCategoriesWithParents = useMemo<
    FetchMaterialCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      materialCategories.map((category) => category.parent)
    );

    return sortBy(
      uniqBy([...materialCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [materialCategories]);

  return (
    <div className="p-4">
      <AlertMessage message={materialCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          materialCategoriesIsPlaceholderData || materialCategoriesFetched
        }
      >
        <ThreeDStockIndexPageMaterialsCategoriesNavBody
          allCustomI18nText={allCustomI18nText}
          filtersNavBasePath={filtersNavBasePath}
          isShowAll={isShowAll}
          materialCategories={materialCategoriesWithParents}
          withNavHead={withNavHead}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNav;
